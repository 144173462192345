import React from 'react'
import { Grid, Button, useTheme } from '@mui/material'
import { PictureAsPdf } from '@mui/icons-material'

const MindMapControlPanel = ({ onGenerateAndView, isCompiling, t }) => {
    const theme = useTheme()

    const containedButtonStyle = {
        height: '56px',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        boxShadow: theme.shadows[2],
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
            boxShadow: theme.shadows[4],
        },
    }

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={4}>
                <Button
                    onClick={onGenerateAndView}
                    variant="contained"
                    startIcon={<PictureAsPdf />}
                    fullWidth
                    sx={containedButtonStyle}
                    disabled={isCompiling}
                >
                    {isCompiling ? t.compiling : t.generateAndViewMindMap}
                </Button>
            </Grid>
        </Grid>
    )
}

export default MindMapControlPanel
