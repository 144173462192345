import React from 'react'
import { MenuItem } from '@mui/material'

const ImportExportMenu = ({ onUpload, onDownload, onDownloadTex, t, onClose }) => {
    const handleUpload = () => {
        onUpload()
        onClose()
    }

    const handleDownload = () => {
        onDownload()
        onClose()
    }

    const handleDownloadTex = () => {
        onDownloadTex()
        onClose()
    }

    return (
        <>
            <MenuItem onClick={handleUpload}>{t.uploadJsonFile}</MenuItem>
            <MenuItem onClick={handleDownload}>{t.downloadJsonFile}</MenuItem>
            <MenuItem onClick={handleDownloadTex}>{t.downloadTexFile}</MenuItem>
        </>
    )
}

export default ImportExportMenu
