import { styled } from '@mui/material/styles'
import { TextField, FormControl } from '@mui/material'

const StyledTextField = styled(TextField)(({ theme, multiline }) => ({
    '& .MuiInputLabel-root': {
        backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : theme.palette.background.paper,
        color: theme.palette.text.primary,
        padding: '0 4px',
        borderRadius: '4px',
        transform: multiline ? 'translate(14px, -6px) scale(0.75)' : 'none',
        transition: 'transform 0.2s, color 0.2s, background-color 0.2s',
        '&.Mui-focused, &.MuiFormLabel-filled': {
            transform: 'translate(14px, -9px) scale(0.75)',
            color: theme.palette.primary.main,
        },
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.mode === 'light' ? '#eceef6' : theme.palette.background.default,
        border: `2px solid transparent`,
        transition: 'border-color 0.3s',
        '& fieldset': {
            top: 0,
            border: 'none',
        },
        '& input, & textarea': {
            paddingTop: multiline ? '8px' : '20px',
            color: theme.palette.text.primary,
        },
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused': {
            borderColor: theme.palette.primary.main,
        },
    },
    '& .MuiInputLabel-root.MuiInputLabel-shrink': {
        transform: 'translate(14px, -9px) scale(0.75)',
        padding: '0 4px',
        backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : theme.palette.background.paper,
        color: theme.palette.text.primary,
    },
}))

const DynamicTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        height: 'auto',
        backgroundColor: theme.palette.mode === 'light' ? '#eceef6' : theme.palette.background.default,
        border: `2px solid transparent`,
        transition: 'border-color 0.3s',
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused': {
            borderColor: theme.palette.primary.main,
        },
    },
    '& .MuiInputBase-input': {
        overflow: 'hidden',
        resize: 'none',
        backgroundColor: theme.palette.mode === 'light' ? '#eceef6' : theme.palette.background.default,
        border: 'none',
        color: theme.palette.text.primary,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiInputLabel-root': {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.mode === 'light' ? '#eceef6' : theme.palette.background.default,
        padding: '0 4px',
        borderRadius: '4px',
        '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.75)',
            padding: '0 4px',
            backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : theme.palette.background.paper,
            color: theme.palette.text.primary,
        },
    },
}))

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    '& .MuiInputLabel-root': {
        backgroundColor: theme.palette.mode === 'light' ? '#ffffff' : theme.palette.background.paper,
        color: theme.palette.text.primary,
        padding: '0 4px',
        borderRadius: '4px',
    },
    '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.mode === 'light' ? '#eceef6' : theme.palette.background.default,
        border: `2px solid transparent`,
        transition: 'border-color 0.3s',
        '& fieldset': {
            border: 'none',
        },
        '&:hover': {
            borderColor: theme.palette.primary.main,
        },
        '&.Mui-focused': {
            borderColor: theme.palette.primary.main,
        },
    },
    '& .MuiSelect-select': {
        color: theme.palette.text.primary,
    },
}))

export { StyledTextField, DynamicTextField, StyledFormControl }
