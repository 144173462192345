import React, { useState, useEffect } from 'react'
import { Typography, Button, Box } from '@mui/material'

const unescapeBackslashes = (str) => str.replace(/\\\\/g, '\\')

const DocumentationContent = ({ onClose, language: initialLanguage }) => {
    const [language, setLanguage] = useState(initialLanguage)

    useEffect(() => {
        setLanguage(initialLanguage)
    }, [initialLanguage])

    const content = {
        en: {
            title: 'TeXMap Documentation',
            description: 'TeXMap is an application for generating mind maps using TikZ.',
            sections: [
                {
                    title: 'Creating and Customizing the Mindmap',
                    content: [
                        "Enter the root node text in the 'Root Node' field.",
                        "To add child nodes, enter the node text in the 'Add Child Node' field and select a parent node from the dropdown menu.",
                        "Click 'Add Node' to add the node to your mind map.",
                        "Use the 'Custom Preamble' tab to add custom LaTeX commands or packages that will be included in the preamble of the generated LaTeX document.",
                        'You can now add images to nodes during creation or editing.',
                    ],
                },
                {
                    title: 'Adding and Managing Images in Nodes',
                    content: [
                        'When creating or editing a node, click the image icon in the text field to upload an image.',
                        'You can add a node with only an image, without text.',
                        'In the edit mode, you can modify the image scale using the slider that appears below the image.',
                        "To remove an image from a node, use the 'Delete Image' button in the edit mode.",
                        'The image will be automatically included in the generated LaTeX code.',
                    ],
                },
                {
                    title: 'Editing Nodes',
                    content: [
                        "Open the 'Edit Nodes' tab to modify existing nodes.",
                        'You can edit the text, move, or delete nodes as needed.',
                        "Use the 'Edge Style' field to customize the appearance of the edge leading to this node (e.g., 'level distance=5cm, rotate=45').",
                        "Use the 'Node Style' field to customize the appearance of the node itself (e.g., 'fill=red, text=white').",
                        'Manage node images: add, replace, resize, or delete images associated with each node.',
                    ],
                },
                {
                    title: 'Customizing Appearance',
                    content: [
                        "Use the 'Layout Options' tab to adjust the global rotation, distance, and angle between nodes for each level.",
                        "The 'Color Options' tab allows you to choose between 'Colors by subtree', 'Single color gradient', or 'Same color per level'.",
                        "In the 'Edge and Node Style' tab, you can select the overall mindmap style (default or custom) and customize edge styles and node shapes.",
                        'For custom style, you can choose different edge styles and node shapes for levels 2 and 3.',
                    ],
                },
                {
                    title: 'Generating LaTeX Code',
                    content: [
                        'Click the floating button in the bottom right to generate and view the mind map in PDF format.',
                        "Use 'View LaTeX Code' to see the generated LaTeX code.",
                        "The 'Download TeX File' option allows you to download the .tex file.",
                        'The generated LaTeX code now includes the necessary commands to incorporate images in your nodes.',
                    ],
                },
                {
                    title: 'Integrating Mathematical Formulas',
                    content: [
                        "Place formulas between single dollars ($...$) and use the \\displaystyle command for 'centered' equations.",
                        'Neither \\[...\\] nor $$...$$ work in a TikZ environment.',
                        'Example of working formula:',
                        <Typography
                            component="pre"
                            sx={{
                                fontFamily: 'monospace',
                                my: 2,
                                p: 2,
                                bgcolor: 'background.paper',
                                borderRadius: 1,
                            }}
                        >
                            {unescapeBackslashes('$\\begin{aligned}...\\end{aligned}$')}
                        </Typography>,
                        'For more complex equations, use the minipage environment:',
                        <Typography
                            component="pre"
                            sx={{
                                fontFamily: 'monospace',
                                my: 2,
                                p: 2,
                                bgcolor: 'background.paper',
                                borderRadius: 1,
                            }}
                        >
                            {unescapeBackslashes(`\\begin{minipage}{.35\\textwidth}
\\begin{align}
2x-3y&=0\\\\
5x-2y&=7
\\end{align}
\\end{minipage}`)}
                        </Typography>,
                    ],
                },
                {
                    title: 'Integrating Computer Code',
                    content: [
                        'Use ^^J at the end of each line to indicate a new line in verbatim and lstlisting environments.',
                        'Example of code integration:',
                        <Typography
                            component="pre"
                            sx={{
                                fontFamily: 'monospace',
                                my: 2,
                                p: 2,
                                bgcolor: 'background.paper',
                                borderRadius: 1,
                            }}
                        >
                            {unescapeBackslashes(`\\begin{minipage}{.4\\textwidth}
\\begin{lstlisting}[language=Python]^^J
from math import exp^^J
print(exp(1))^^J
\\end{lstlisting}
\\end{minipage}`)}
                        </Typography>,
                        "To preserve spaces, precede them with a backslash: '\\ \\ \\ \\' for four spaces (useful for Python).",
                    ],
                },
                {
                    title: 'Using Tables in Nodes',
                    content: [
                        'When including tables in your nodes, different LaTeX environments require different handling:',
                        "The 'table' environment must be wrapped in a minipage environment to display correctly.",
                        "The 'tabular' environment can be used directly without a minipage.",
                        'Example of using a table environment:',
                        <Typography
                            component="pre"
                            sx={{
                                fontFamily: 'monospace',
                                my: 2,
                                p: 2,
                                bgcolor: 'background.paper',
                                borderRadius: 1,
                            }}
                        >
                            {unescapeBackslashes(`\\begin{minipage}{0.4\\textwidth}
\\begin{table}[h]
\\centering
\\begin{tabular}{|c|c|}
\\hline
Header 1 & Header 2 \\\\
\\hline
Content 1 & Content 2 \\\\
\\hline
\\end{tabular}
\\caption{A sample table}
\\end{table}
\\end{minipage}`)}
                        </Typography>,
                    ],
                },
                {
                    title: 'Customizing Node Styles',
                    content: [
                        'You can customize the appearance of your nodes using TikZ style options. Here are some examples:',
                        'Change background color: fill=lightblue',
                        'Modify text color: text=darkred',
                        'Add a border: draw=black, thick',
                        'Round corners: rounded corners',
                        'Example usage:',
                        <Typography
                            component="pre"
                            sx={{
                                fontFamily: 'monospace',
                                my: 2,
                                p: 2,
                                bgcolor: 'background.paper',
                                borderRadius: 1,
                            }}
                        >
                            {unescapeBackslashes(
                                `\\node[concept, fill=lightblue, text=darkred, draw=black, thick, rounded corners] {Node text};`
                            )}
                        </Typography>,
                    ],
                },
            ],
            closeButton: 'Close',
            switchLanguage: 'Passer en Français',
        },
        fr: {
            title: 'Documentation de TeXMap',
            description: 'TeXMap est une application de génération de cartes mentales utilisant TikZ.',
            sections: [
                {
                    title: 'Création et personnalisation de la carte mentale',
                    content: [
                        "Entrez le texte du nœud racine dans le champ 'Nœud racine'.",
                        "Pour ajouter des nœuds enfants, entrez le texte du nœud dans le champ 'Ajouter un nœud enfant' et sélectionnez un nœud parent dans le menu déroulant.",
                        "Cliquez sur 'Ajouter un nœud' pour ajouter le nœud à votre carte mentale.",
                        "Utilisez l'onglet 'Préambule personnalisé' pour ajouter des commandes LaTeX personnalisées ou des packages qui seront inclus dans le préambule du document LaTeX généré.",
                        "Vous pouvez maintenant ajouter des images aux nœuds lors de la création ou de l'édition.",
                    ],
                },
                {
                    title: 'Ajout et gestion des images dans les nœuds',
                    content: [
                        "Lors de la création ou de l'édition d'un nœud, cliquez sur l'icône d'image dans le champ de texte pour télécharger une image.",
                        'Vous pouvez ajouter un nœud avec uniquement une image, sans texte.',
                        "En mode édition, vous pouvez modifier l'échelle de l'image à l'aide du curseur qui apparaît sous l'image.",
                        "Pour supprimer une image d'un nœud, utilisez le bouton 'Supprimer l'image' en mode édition.",
                        "L'image sera automatiquement incluse dans le code LaTeX généré.",
                    ],
                },
                {
                    title: 'Édition des nœuds',
                    content: [
                        "Ouvrez l'onglet 'Éditer les nœuds' pour modifier les nœuds existants.",
                        'Vous pouvez éditer le texte, déplacer ou supprimer les nœuds selon vos besoins.',
                        "Utilisez le champ 'Style de l'arête' pour personnaliser l'apparence de l'arête menant à ce nœud (par exemple, 'level distance=5cm, rotate=45').",
                        "Utilisez le champ 'Style du nœud' pour personnaliser l'apparence du nœud lui-même (par exemple, 'fill=red, text=white').",
                        'Gérez les images des nœuds : ajoutez, remplacez, redimensionnez ou supprimez les images associées à chaque nœud.',
                    ],
                },
                {
                    title: "Personnalisation de l'apparence",
                    content: [
                        "Utilisez l'onglet 'Options de mise en page' pour ajuster la rotation globale, la distance et l'angle entre les nœuds pour chaque niveau.",
                        "L'onglet 'Options de couleur' vous permet de choisir entre 'Une couleur par sous-arbre', 'Dégradé d'une seule couleur', ou 'Une couleur par niveau'.",
                        "Dans l'onglet 'Style des arêtes et des nœuds', vous pouvez sélectionner le style global de la carte mentale (par défaut ou personnalisé) et personnaliser les styles des arêtes et les formes des nœuds.",
                        "Pour le style personnalisé, vous pouvez choisir différents styles d'arêtes et formes de nœuds pour les niveaux 2 et 3.",
                    ],
                },
                {
                    title: 'Génération du code LaTeX',
                    content: [
                        'Cliquez sur le bouton flottant en bas à droite pour générer et visualiser la carte mentale en format PDF.',
                        "Utilisez 'Voir le code LaTeX' pour voir le code LaTeX généré.",
                        "L'option 'Télécharger le fichier TeX' vous permet de télécharger le fichier .tex.",
                        'Le code LaTeX généré inclut maintenant les commandes nécessaires pour incorporer des images dans vos nœuds.',
                    ],
                },
                {
                    title: 'Intégration de formules mathématiques',
                    content: [
                        "Placez les formules entre des simples dollars ($...$) et utilisez la commande \\displaystyle pour les équations 'centrées'.",
                        'Ni \\[...\\], ni $$...$$ ne fonctionnent dans un environnement TikZ.',
                        'Exemple de formule fonctionnelle :',
                        <Typography
                            component="pre"
                            sx={{
                                fontFamily: 'monospace',
                                my: 2,
                                p: 2,
                                bgcolor: 'background.paper',
                                borderRadius: 1,
                            }}
                        >
                            {unescapeBackslashes('$\\begin{aligned}...\\end{aligned}$')}
                        </Typography>,
                        "Pour des équations plus complexes, utilisez l'environnement minipage :",
                        <Typography
                            component="pre"
                            sx={{
                                fontFamily: 'monospace',
                                my: 2,
                                p: 2,
                                bgcolor: 'background.paper',
                                borderRadius: 1,
                            }}
                        >
                            {unescapeBackslashes(`\\begin{minipage}{.35\\textwidth}
\\begin{align}
2x-3y&=0\\\\
5x-2y&=7
\\end{align}
\\end{minipage}`)}
                        </Typography>,
                    ],
                },
                {
                    title: 'Intégration de code informatique',
                    content: [
                        'Utilisez ^^J à la fin de chaque ligne pour indiquer une nouvelle ligne dans les environnements verbatim et lstlisting.',
                        "Exemple d'intégration de code :",
                        <Typography
                            component="pre"
                            sx={{
                                fontFamily: 'monospace',
                                my: 2,
                                p: 2,
                                bgcolor: 'background.paper',
                                borderRadius: 1,
                            }}
                        >
                            {unescapeBackslashes(`\\begin{minipage}{.4\\textwidth}
\\begin{lstlisting}[language=Python]^^J
from math import exp^^J
print(exp(1))^^J
\\end{lstlisting}
\\end{minipage}`)}
                        </Typography>,
                        "Pour préserver les espaces, précédez-les d'une contre-oblique : '\\ \\ \\ \\' pour quatre espaces (utile pour Python).",
                    ],
                },
                {
                    title: 'Utilisation des tableaux dans les nœuds',
                    content: [
                        "Lors de l'inclusion de tableaux dans vos nœuds, différents environnements LaTeX nécessitent un traitement différent :",
                        "L'environnement 'table' doit être enveloppé dans un environnement minipage pour s'afficher correctement.",
                        "L'environnement 'tabular' peut être utilisé directement sans minipage.",
                        "Exemple d'utilisation de l'environnement table :",
                        <Typography
                            component="pre"
                            sx={{
                                fontFamily: 'monospace',
                                my: 2,
                                p: 2,
                                bgcolor: 'background.paper',
                                borderRadius: 1,
                            }}
                        >
                            {unescapeBackslashes(`\\begin{minipage}{0.4\\textwidth}
\\begin{table}[h]
\\centering
\\begin{tabular}{|c|c|}
\\hline
En-tête 1 & En-tête 2 \\\\
\\hline
Contenu 1 & Contenu 2 \\\\
\\hline
\\end{tabular}
\\caption{Un exemple de tableau}
\\end{table}
\\end{minipage}`)}
                        </Typography>,
                    ],
                },
                {
                    title: 'Personnalisation des styles de nœuds',
                    content: [
                        "Vous pouvez personnaliser l'apparence de vos nœuds en utilisant les options de style TikZ. Voici quelques exemples :",
                        'Changer la couleur de fond : fill=lightblue',
                        'Modifier la couleur du texte : text=darkred',
                        'Ajouter une bordure : draw=black, thick',
                        'Arrondir les coins : rounded corners',
                        "Exemple d'utilisation :",
                        <Typography
                            component="pre"
                            sx={{
                                fontFamily: 'monospace',
                                my: 2,
                                p: 2,
                                bgcolor: 'background.paper',
                                borderRadius: 1,
                            }}
                        >
                            {unescapeBackslashes(
                                `\\node[concept, fill=lightblue, text=darkred, draw=black, thick, rounded corners] {Texte du nœud};`
                            )}
                        </Typography>,
                    ],
                },
            ],
            closeButton: 'Fermer',
            switchLanguage: 'Switch to English',
        },
    }

    const { title, description, sections, closeButton, switchLanguage } = content[language]

    const toggleLanguage = () => {
        setLanguage((prevLang) => (prevLang === 'en' ? 'fr' : 'en'))
    }

    return (
        <Box sx={{ maxHeight: '80vh', overflow: 'auto' }}>
            <Typography variant="h4" gutterBottom>
                {title}
            </Typography>
            <Typography variant="body1" paragraph>
                {description}
            </Typography>
            {sections.map((section, index) => (
                <Box key={index} sx={{ mb: 3 }}>
                    <Typography variant="h6" gutterBottom>
                        {section.title}
                    </Typography>
                    <ul>
                        {section.content.map((item, itemIndex) => (
                            <li key={itemIndex}>
                                {typeof item === 'string' ? (
                                    <Typography variant="body1" paragraph>
                                        {item}
                                    </Typography>
                                ) : (
                                    item
                                )}
                            </li>
                        ))}
                    </ul>
                </Box>
            ))}
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="contained" onClick={onClose}>
                    {closeButton}
                </Button>
                <Button variant="outlined" onClick={toggleLanguage}>
                    {switchLanguage}
                </Button>
            </Box>
        </Box>
    )
}

export default DocumentationContent
