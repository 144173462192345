import React, { useState } from 'react'
import { AppBar, Toolbar, Typography, IconButton, Box, useMediaQuery, Menu, MenuItem, styled } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Brightness4Icon from '@mui/icons-material/Brightness4'
import Brightness7Icon from '@mui/icons-material/Brightness7'
import HelpIcon from '@mui/icons-material/Help'
import MenuIcon from '@mui/icons-material/Menu'
import LanguageIcon from '@mui/icons-material/Language'
import ImportExportIcon from '@mui/icons-material/ImportExport'
import HomeIcon from '@mui/icons-material/Home'
import ImportExportMenu from './ImportExportMenu'
import LanguageSelector from './LanguageSelector'

const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: theme.palette.primary.main,
                '& .MuiSvgIcon-root': {
                    color: theme.palette.common.white,
                },
            },
        },
    },
}))

const ResponsiveAppBar = ({
    isDarkMode,
    toggleDarkMode,
    setIsDocumentationOpen,
    language,
    toggleLanguage,
    uploadJSON,
    downloadJSON,
    downloadLatexCode,
    t,
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null)
    const [languageAnchorEl, setLanguageAnchorEl] = useState(null)
    const [importExportAnchorEl, setImportExportAnchorEl] = useState(null)

    const handleMobileMenuOpen = (event) => {
        setMobileMenuAnchorEl(event.currentTarget)
    }

    const handleMobileMenuClose = () => {
        setMobileMenuAnchorEl(null)
    }

    const handleLanguageClick = (event) => {
        setLanguageAnchorEl(event.currentTarget)
    }

    const handleLanguageClose = () => {
        setLanguageAnchorEl(null)
    }

    const handleImportExportClick = (event) => {
        setImportExportAnchorEl(event.currentTarget)
    }

    const handleImportExportClose = () => {
        setImportExportAnchorEl(null)
    }

    return (
        <AppBar
            position="static"
            sx={{
                backgroundColor:
                    theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.primary.main,
                color: theme.palette.mode === 'dark' ? theme.palette.text.primary : theme.palette.primary.contrastText,
            }}
        >
            <Toolbar sx={{ justifyContent: 'space-between', padding: theme.spacing(0, 1), position: 'relative' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', position: 'absolute', left: theme.spacing(1) }}>
                    <IconButton
                        color="inherit"
                        component="a"
                        href="https://apps.edulatex.xyz"
                        rel="noopener noreferrer"
                        aria-label="Home"
                    >
                        <HomeIcon />
                    </IconButton>
                    {isMobile && (
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={handleMobileMenuOpen}
                        >
                            <MenuIcon />
                        </IconButton>
                    )}
                </Box>

                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        position: 'absolute',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        fontSize: isMobile ? '1.15rem' : '1.25rem',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        width: '60%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {t.title}
                </Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', position: 'absolute', right: theme.spacing(1) }}>
                    {!isMobile && (
                        <>
                            <IconButton onClick={toggleDarkMode} color="inherit">
                                {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                            </IconButton>
                            <IconButton color="inherit" onClick={handleImportExportClick} aria-label={t.importExport}>
                                <ImportExportIcon />
                            </IconButton>
                            <IconButton color="inherit" onClick={() => setIsDocumentationOpen(true)}>
                                <HelpIcon />
                            </IconButton>
                            <IconButton color="inherit" onClick={handleLanguageClick} aria-label={t.changeLanguage}>
                                <LanguageIcon />
                            </IconButton>
                        </>
                    )}
                </Box>

                <StyledMenu
                    anchorEl={mobileMenuAnchorEl}
                    open={Boolean(mobileMenuAnchorEl)}
                    onClose={handleMobileMenuClose}
                >
                    <MenuItem onClick={handleLanguageClick}>
                        <LanguageIcon />
                        <Typography sx={{ ml: 1 }}>{t.changeLanguage}</Typography>
                    </MenuItem>
                    <MenuItem onClick={handleImportExportClick}>
                        <ImportExportIcon />
                        <Typography sx={{ ml: 1 }}>{t.importExport}</Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            toggleDarkMode()
                            handleMobileMenuClose()
                        }}
                    >
                        {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                        <Typography sx={{ ml: 1 }}>{isDarkMode ? t.lightMode : t.darkMode}</Typography>
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setIsDocumentationOpen(true)
                            handleMobileMenuClose()
                        }}
                    >
                        <HelpIcon />
                        <Typography sx={{ ml: 1 }}>{t.documentation}</Typography>
                    </MenuItem>
                </StyledMenu>

                <StyledMenu anchorEl={languageAnchorEl} open={Boolean(languageAnchorEl)} onClose={handleLanguageClose}>
                    <LanguageSelector
                        currentLanguage={language}
                        toggleLanguage={(lang) => {
                            toggleLanguage(lang)
                            handleLanguageClose()
                        }}
                        t={t}
                        onClose={handleLanguageClose}
                    />
                </StyledMenu>

                <StyledMenu
                    anchorEl={importExportAnchorEl}
                    open={Boolean(importExportAnchorEl)}
                    onClose={handleImportExportClose}
                >
                    <ImportExportMenu
                        onUpload={(e) => {
                            uploadJSON(e)
                            handleImportExportClose()
                        }}
                        onDownload={() => {
                            downloadJSON()
                            handleImportExportClose()
                        }}
                        onDownloadTex={() => {
                            downloadLatexCode()
                            handleImportExportClose()
                        }}
                        t={t}
                        onClose={handleImportExportClose}
                    />
                </StyledMenu>
            </Toolbar>
        </AppBar>
    )
}

export default ResponsiveAppBar
