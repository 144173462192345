import React from 'react'
import { MenuItem } from '@mui/material'

const LanguageSelector = ({ currentLanguage, toggleLanguage, t, onClose }) => {
    const handleLanguageToggle = (lang) => {
        toggleLanguage(lang)
        onClose()
    }

    return (
        <>
            <MenuItem onClick={() => handleLanguageToggle('en')} selected={currentLanguage === 'en'}>
                English
            </MenuItem>
            <MenuItem onClick={() => handleLanguageToggle('fr')} selected={currentLanguage === 'fr'}>
                Français
            </MenuItem>
        </>
    )
}

export default LanguageSelector
