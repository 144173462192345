import React, { useState, useEffect, useCallback } from 'react'
import {
    ThemeProvider,
    createTheme,
    alpha,
    CssBaseline,
    Box,
    Container,
    Grid,
    Paper,
    Typography,
    TextField,
    Button,
    Select,
    MenuItem,
    InputLabel,
    List,
    ListItem,
    IconButton,
    Dialog,
    Slider,
    DialogTitle,
    DialogContent,
    DialogActions,
    useMediaQuery,
    Modal,
} from '@mui/material'
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    KeyboardArrowUp as KeyboardArrowUpIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material'
import { CircularProgress, Backdrop } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { deepmerge } from '@mui/utils'
import DocumentationContent from './components/DocumentationContent'
import { Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { Tabs, Tab } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ImageIcon from '@mui/icons-material/Image'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { vs, vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { useTheme } from '@mui/material/styles'
import MindMapJSONIO from './components/MindMapJSONIO'
import MindMapControlPanel from './components/MindMapControlPanel'
import { StyledFormControl, StyledTextField, DynamicTextField } from './components/StyledComponents'
import ResponsiveAppBar from './components/ResponsiveAppBar'

const baseTheme = {
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h4: {
            fontWeight: 600,
        },
        h6: {
            fontWeight: 600,
        },
    },
    components: {
        MuiPaper: {
            defaultProps: {
                elevation: 2,
            },
            styleOverrides: {
                root: {
                    borderRadius: 8,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
    },
}

const lightTheme = createTheme(
    deepmerge(baseTheme, {
        palette: {
            mode: 'light',
            primary: {
                main: '#3f51b5',
            },
            secondary: {
                main: '#f50057',
            },
            background: {
                // default: '#f5f5f5',
                // default: '#4154af26',
                default: '#e3e6f2',
            },
        },
    })
)

const darkTheme = createTheme(
    deepmerge(baseTheme, {
        palette: {
            mode: 'dark',
            primary: {
                main: '#90caf9',
            },
            secondary: {
                main: '#f48fb1',
            },
            background: {
                default: '#303030',
                paper: '#424242',
            },
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: ({ theme }) => ({
                        '& .MuiInputLabel-root': {
                            backgroundColor: alpha(theme.palette.background.paper, 0.8),
                            color: theme.palette.text.primary,
                            '&.Mui-focused, &.MuiFormLabel-filled': {
                                color: theme.palette.primary.main,
                            },
                        },
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: alpha(theme.palette.background.paper, 0.6),
                            borderColor: 'transparent',
                            '&:hover': {
                                borderColor: theme.palette.primary.main,
                            },
                            '&.Mui-focused': {
                                borderColor: theme.palette.primary.main,
                            },
                            '& input, & textarea': {
                                color: theme.palette.text.primary,
                            },
                        },
                    }),
                },
            },
        },
    })
)

const translations = {
    en: {
        title: 'Mind Map Generator',
        addNode: 'Add Node',
        editNodes: 'Edit Nodes',
        saveChanges: 'Save Changes',
        enterNodeText: 'Enter node text',
        parentNode: 'Parent Node',
        rootNoParent: 'No Parent',
        distance: 'Distance',
        angle: 'Angle',
        layoutOptions: 'Layout Options',
        level1: 'Level 1',
        level2: 'Level 2',
        level3: 'Level 3',
        level1Distance: 'Level 1 Distance',
        level1SiblingAngle: 'Level 1 Sibling Angle',
        level2Distance: 'Level 2 Distance',
        level2SiblingAngle: 'Level 2 Sibling Angle',
        level3Distance: 'Level 3 Distance',
        level3SiblingAngle: 'Level 3 Sibling Angle',
        colorOptions: 'Color Options',
        colorStyle: 'Color Style',
        gradientBaseColor: 'Gradient Base Color',
        hideColorPairs: 'Hide Color Pairs',
        showColorPairs: 'Show Color Pairs',
        addColorPair: 'Add Color Pair',
        hideLevelColors: 'Hide Level Colors',
        showLevelColors: 'Show Level Colors',
        rootNodeStyle: 'Root Node Style',
        filled: 'Filled',
        outlined: 'Outlined (white fill)',
        nodeShapes: 'Node Shapes',
        level2NodeShape: 'Level 2 Node Shape',
        level3NodeShape: 'Level 3 Node Shape',
        ellipse: 'Ellipse',
        rectangle: 'Rectangle',
        edgeAndNodeStyle: 'Edge and Node Style',
        edgeStyleLevel1to2: 'Edge Style (Level 1 to 2)',
        curvedEdges: 'Curved edges',
        straightEdges: 'Straight edges',
        zigzagDecoration: 'Zigzag edges',
        defaultMindmapStyle: 'TikZ Default Style',
        customMindmapStyle: 'Custom Style',
        mindmapStyle: 'Mindmap Style',
        edgeStyleLevel2to3: 'Edge Style (Level 2 to 3)',
        generateMindMap: 'Generate Mind Map',
        generateAndViewMindMap: 'View Mind Map',
        viewLatexCode: 'View LaTeX Code',
        downloadTexFile: 'Download the TeX File',
        generatedLatexCode: 'Generated LaTeX Code',
        copyToClipboard: 'Copy to Clipboard',
        close: 'Close',
        documentation: 'Documentation',
        root: 'Root',
        colorBySubtree: 'One Color Per Subtree',
        singleColorGradient: 'Single color gradient',
        sameColorPerLevel: 'One Color Per Level',
        default: 'default',
        custom: 'Custom',
        backgroundColor: 'Background Color',
        fontColor: 'Font Color',
        validColorName: 'Enter a valid LaTeX color name',
        globalRotation: 'Global Rotation',
        ball: 'Ball',
        rotation: 'Rotation',
        customStyle: 'Custom Style',
        nodeText: 'Node Text',
        nodeStyle: 'Node Style',
        edgeStyle: 'Edge Style',
        customPreamble: 'Custom LaTeX Preamble',
        editInTeXlive: 'Edit in TeXlive.net',
        collapseAll: 'Collapse all sections',
        expandAll: 'Expand all sections',
        mindmapCreation: 'Mindmap Creation',
        rootNodeTitle: 'Root Node',
        enterRootNodeText: 'Enter the main topic of your mind map',
        addChildNode: 'Add Child Node',
        rootNodeDefault: 'Root (Main Topic)',
        noParent: 'No Parent',
        copiedToClipboard: 'LaTeX code copied to clipboard!',
        promoteNode: 'Promote Node',
        demoteNode: 'Demote Node',
        moveNodeUp: 'Move Node Up',
        moveNodeDown: 'Move Node Down',
        deleteNode: 'Delete Node',
        enterNewRootNode: 'Enter New Root Node',
        newRootNodeText: 'New Root Node Text',
        newRootNodePlaceholder: 'Enter the text for the new root node',
        cancel: 'Cancel',
        confirm: 'Confirm',
        demoteRootNode: 'Demote Root Node',
        uploadJsonFile: 'Upload a JSON File',
        downloadJsonFile: 'Download the JSON File',
        changeLanguage: 'Change Language',
        lightMode: 'Light Mode',
        darkMode: 'Dark Mode',
        english: 'English',
        french: 'French',
        fileOperations: 'File Operations',
        buyMeACoffee: 'Buy me a coffee',
        importExport: 'Import/Export',
        deleteImage: 'Delete Image',
    },

    fr: {
        title: 'Générateur de cartes mentales',
        addNode: 'Ajouter un nœud',
        editNodes: 'Éditer les nœuds',
        saveChanges: 'Enregistrer les changements',
        enterNodeText: 'Entrez le texte du nœud',
        parentNode: 'Nœud parent',
        rootNoParent: 'Pas de parent',
        distance: 'Distance ',
        angle: 'Angle ',
        layoutOptions: 'Mise en page',
        level1: 'Niveau 1',
        level2: 'Niveau 2',
        level3: 'Niveau 3',
        level1Distance: 'Distance de niveau 1',
        level1SiblingAngle: 'Angle entre les nœuds de niveau 1',
        level2Distance: 'Distance de niveau 2',
        level2SiblingAngle: 'Angle entre les nœuds de niveau 2',
        level3Distance: 'Distance de niveau 3',
        level3SiblingAngle: 'Angle entre les nœuds de niveau 3',
        colorOptions: 'Couleur',
        colorStyle: 'Style de couleur',
        hideColorPairs: 'Masquer les paires de couleurs',
        showColorPairs: 'Afficher les paires de couleurs',
        addColorPair: 'Ajouter une paire de couleurs',
        hideLevelColors: 'Masquer les couleurs',
        showLevelColors: 'Afficher les couleurs',
        rootNodeStyle: 'Style du nœud racine',
        filled: 'Plein',
        outlined: 'Contour (intérieur blanc)',
        nodeShapes: 'Formes des nœuds',
        level2NodeShape: 'Forme des nœuds de niveau 2',
        level3NodeShape: 'Forme des nœuds de niveau 3',
        ellipse: 'Ellipse',
        rectangle: 'Rectangle',
        edgeAndNodeStyle: 'Style des arêtes et des nœuds',
        edgeStyleLevel1to2: 'Style des arêtes du niveau 1 à 2',
        curvedEdges: 'Arêtes courbées',
        straightEdges: 'Arêtes droites',
        zigzagDecoration: 'Arêtes en zigzag',
        defaultMindmapStyle: 'Style TikZ par défaut',
        customMindmapStyle: 'Style personnalisé',
        mindmapStyle: 'Style de la carte mentale',
        edgeStyleLevel2to3: 'Style des arêtes du niveau 2 à 3',
        generateMindMap: 'Générer la carte mentale',
        generateAndViewMindMap: 'Voir la carte mentale',
        viewLatexCode: 'Voir le code LaTeX',
        downloadTexFile: 'Télécharger le fichier TeX',
        generatedLatexCode: 'Code LaTeX généré',
        copyToClipboard: 'Copier dans le presse-papiers',
        close: 'Fermer',
        documentation: 'Documentation',
        root: 'Racine',
        colorBySubtree: 'Une couleur par sous-arbre',
        singleColorGradient: "Dégradé d'une seule couleur",
        sameColorPerLevel: 'Une couleur par niveau',
        default: 'par défaut',
        custom: 'Personnalisé',
        backgroundColor: 'Couleur de fond',
        fontColor: 'Couleur de police',
        validColorName: 'Saisir un nom de couleur LaTeX valide',
        globalRotation: 'Rotation globale',
        ball: 'Sphère',
        rotation: 'Rotation',
        customStyle: 'Style personnalisé',
        nodeText: 'Texte du nœud',
        nodeStyle: 'Style du nœud',
        edgeStyle: "Style de l'arête",
        customPreamble: 'Préambule LaTeX personnalisé',
        editInTeXlive: 'Éditer sur TeXlive.net',
        collapseAll: 'Réduire toutes les sections',
        expandAll: 'Développer toutes les sections',
        mindmapCreation: 'Création de la Carte Mentale',
        rootNodeTitle: 'Nœud racine',
        enterRootNodeText: 'Entrez le sujet principal de votre carte mentale',
        addChildNode: 'Ajouter un nœud enfant',
        rootNodeDefault: 'Racine (Sujet Principal)',
        noParent: 'Pas de parent',
        copiedToClipboard: 'Code LaTeX copié dans le presse-papier !',
        promoteNode: 'Promouvoir le nœud',
        demoteNode: 'Rétrograder le nœud',
        moveNodeUp: 'Déplacer le nœud vers le haut',
        moveNodeDown: 'Déplacer le nœud vers le bas',
        deleteNode: 'Supprimer le nœud',
        enterNewRootNode: 'Entrer le nouveau nœud racine',
        newRootNodeText: 'Texte du nouveau nœud racine',
        newRootNodePlaceholder: 'Entrez le texte pour le nouveau nœud racine',
        cancel: 'Annuler',
        confirm: 'Confirmer',
        demoteRootNode: 'Rétrograder le nœud racine',
        uploadJsonFile: 'Importer un fichier JSON',
        downloadJsonFile: 'Télécharger le fichier JSON',
        changeLanguage: 'Changer de langue',
        fileOperations: 'Fichiers',
        lightMode: 'Mode clair',
        darkMode: 'Mode sombre',
        english: 'Anglais',
        french: 'Français',
        buyMeACoffee: 'Offrez-moi un café',
        importExport: 'Importer/Exporter',
        deleteImage: "Supprimer l'image",
    },
}

const LatexCodeDialog = ({ open, onClose, latexCode, onCopy, t }) => {
    const theme = useTheme()
    const isDarkMode = theme.palette.mode === 'dark'

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                style: {
                    borderRadius: 12,
                    padding: '18px',
                },
            }}
        >
            <DialogTitle
                sx={{
                    m: 0,
                    p: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h6">{t.generatedLatexCode}</Typography>
                <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ p: 4 }}>
                <Paper elevation={3} sx={{ p: 2, borderRadius: 2, maxHeight: '60vh', overflow: 'auto' }}>
                    <SyntaxHighlighter
                        language="latex"
                        style={isDarkMode ? vs2015 : vs}
                        customStyle={{
                            fontSize: '14px',
                            lineHeight: '1.5',
                            padding: '16px',
                            backgroundColor: theme.palette.background.paper,
                        }}
                    >
                        {latexCode}
                    </SyntaxHighlighter>
                </Paper>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'flex-start', p: 3 }}>
                <Button onClick={onCopy} variant="contained" startIcon={<ContentCopyIcon />} sx={{ borderRadius: 2 }}>
                    {t.copyToClipboard}
                </Button>
                <Button onClick={onClose} variant="outlined" sx={{ borderRadius: 2, ml: 2 }}>
                    {t.close}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const App = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
    const [isDarkMode, setIsDarkMode] = useState(false)
    const [language, setLanguage] = useState(() => {
        const params = new URLSearchParams(window.location.search)
        const langParam = params.get('lang')
        return langParam === 'fr' ? 'fr' : 'en'
    })

    useEffect(() => {
        const now = new Date()
        const hour = now.getHours()
        const isNightTime = hour < 8 || hour >= 20
        setIsDarkMode(isNightTime || prefersDarkMode)
    }, [prefersDarkMode])

    const theme = isDarkMode ? darkTheme : lightTheme

    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode)
    }

    const t = translations[language]

    const [nodes, setNodes] = useState([
        {
            id: 1,
            text: '',
            parentId: null,
            customDistance: null,
            customAngle: null,
            image: null,
        },
    ])

    const [currentNode, setCurrentNode] = useState('')
    const [currentParentId, setCurrentParentId] = useState(1)
    const [levelOneDistance, setLevelOneDistance] = useState(8.5)
    const [levelOneSiblingAngle, setLevelOneSiblingAngle] = useState(90)
    const [levelTwoDistance, setLevelTwoDistance] = useState(5.5)
    const [levelTwoSiblingAngle, setLevelTwoSiblingAngle] = useState(60)
    const [latexCode, setLatexCode] = useState('')
    const [openLatexDialog, setOpenLatexDialog] = useState(false)
    const [edgeStyle, setEdgeStyle] = useState('straight')
    const [edgeStyleLevel3, setEdgeStyleLevel3] = useState('straight')
    const [levelThreeDistance, setLevelThreeDistance] = useState(4.5)
    const [levelThreeSiblingAngle, setLevelThreeSiblingAngle] = useState(60)
    const [colorStyle, setColorStyle] = useState('subtree')
    const [gradientBaseColor, setGradientBaseColor] = useState('Indigo')
    const [levelColors, setLevelColors] = useState({
        root: { background: 'Black', font: 'white' },
        level1: { background: 'RoyalBlue', font: 'white' },
        level2: { background: 'ForestGreen', font: 'white' },
        level3: { background: 'Crimson', font: 'white' },
    })
    const [rootStyle, setRootStyle] = useState('filled')
    const [showColorPairs, setShowColorPairs] = useState(false)
    const [colorPairs, setColorPairs] = useState([
        { background: 'Black', font: 'white' },
        { background: 'RoyalBlue', font: 'white' },
        { background: 'ForestGreen', font: 'white' },
        { background: 'Crimson', font: 'white' },
        { background: 'DarkOrange', font: 'white' },
        { background: 'MediumOrchid', font: 'white' },
        { background: 'Teal', font: 'white' },
        { background: 'Sienna', font: 'white' },
        { background: 'DodgerBlue', font: 'white' },
        { background: 'OliveDrab', font: 'white' },
    ])
    const [level2Shape, setLevel2Shape] = useState('rectangle')
    const [level3Shape, setLevel3Shape] = useState('ellipse')
    const [mindmapStyle, setMindmapStyle] = useState('custom')
    const [isDocumentationOpen, setIsDocumentationOpen] = useState(false)
    const [globalRotation, setGlobalRotation] = useState(0)
    const [customPreamble, setCustomPreamble] = useState('')
    const [openNotification, setOpenNotification] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [notificationMessage, setNotificationMessage] = useState('')
    const [openDemoteRootDialog, setOpenDemoteRootDialog] = useState(false)
    const [newRootText, setNewRootText] = useState('')
    const [isCompiling, setIsCompiling] = useState(false)
    const [newNodeImage, setNewNodeImage] = useState(null)
    const [newNodeImageScale, setNewNodeImageScale] = useState(100)
    const { uploadJSON, downloadJSON } = MindMapJSONIO({
        nodes,
        setNodes,
        customPreamble,
        setCustomPreamble,
        globalRotation,
        setGlobalRotation,
        levelOneDistance,
        setLevelOneDistance,
        levelTwoDistance,
        setLevelTwoDistance,
        levelThreeDistance,
        setLevelThreeDistance,
        levelOneSiblingAngle,
        setLevelOneSiblingAngle,
        levelTwoSiblingAngle,
        setLevelTwoSiblingAngle,
        levelThreeSiblingAngle,
        setLevelThreeSiblingAngle,
        mindmapStyle,
        setMindmapStyle,
        edgeStyle,
        setEdgeStyle,
        edgeStyleLevel3,
        setEdgeStyleLevel3,
        rootStyle,
        setRootStyle,
        level2Shape,
        setLevel2Shape,
        level3Shape,
        setLevel3Shape,
        colorStyle,
        setColorStyle,
    })

    useEffect(() => {
        console.log('Current nodes:', nodes)
    }, [nodes])

    const getRootId = () => {
        const rootNode = nodes.find((node) => node.parentId === null)
        return rootNode ? rootNode.id : null
    }

    const addNode = () => {
        if (currentNode.trim() !== '' || newNodeImage) {
            const newNode = {
                id: Math.max(...nodes.map((n) => n.id)) + 1,
                text: currentNode.trim(),
                parentId: currentParentId,
                edgeStyle: '',
                nodeStyle: '',
                image: newNodeImage,
                imageScale: newNodeImageScale,
            }
            console.log('Adding new node:', newNode)
            setNodes((prevNodes) => {
                const rootId = getRootId()
                const insertIndex =
                    currentParentId === null
                        ? prevNodes.findIndex((n) => n.id === rootId) + 1
                        : prevNodes.findIndex((n) => n.id === currentParentId) + 1
                return [...prevNodes.slice(0, insertIndex), newNode, ...prevNodes.slice(insertIndex)]
            })
            setCurrentNode('')
            setNewNodeImage(null)
            setNewNodeImageScale(100)
        }
    }
    const renderNodeOptions = () => {
        const rootId = getRootId()
        return nodes.map((node) => (
            <MenuItem key={node.id} value={node.id} style={{ paddingLeft: getMenuItemPadding(node.id) }}>
                {node.id === rootId ? `${node.text || t.rootNodeDefault}` : node.text}
            </MenuItem>
        ))
    }

    const updateNodeText = (id, newText) => {
        setNodes((prevNodes) => prevNodes.map((node) => (node.id === id ? { ...node, text: newText } : node)))
    }

    const updateNodeProperty = (id, property, value) => {
        setNodes((prevNodes) => prevNodes.map((node) => (node.id === id ? { ...node, [property]: value } : node)))
    }

    const deleteNode = (id) => {
        setNodes((prevNodes) => {
            const deleteNodeAndChildren = (nodeId) => {
                const nodesToDelete = [nodeId]
                prevNodes.forEach((node) => {
                    if (node.parentId === nodeId) {
                        nodesToDelete.push(...deleteNodeAndChildren(node.id))
                    }
                })
                return nodesToDelete
            }

            const idsToDelete = deleteNodeAndChildren(id)
            return prevNodes.filter((node) => !idsToDelete.includes(node.id))
        })
    }

    const getNodeLevel = useCallback(
        (nodeId) => {
            let level = 0
            let currentNodeId = nodeId
            const nodeMap = new Map(nodes.map((node) => [node.id, node]))

            while (currentNodeId !== null) {
                const currentNode = nodeMap.get(currentNodeId)
                if (!currentNode) break
                if (currentNode.parentId === null) break
                level++
                currentNodeId = currentNode.parentId
            }
            return level
        },
        [nodes]
    )

    const getDescendants = (nodes, parentId) => {
        const descendants = []
        const nodeMap = new Map(nodes.map((node) => [node.id, node]))

        const findDescendants = (id) => {
            for (const node of nodeMap.values()) {
                if (node.parentId === id) {
                    descendants.push(node)
                    findDescendants(node.id)
                }
            }
        }

        findDescendants(parentId)
        return descendants
    }

    const promoteNode = (nodeId) => {
        setNodes((prevNodes) => {
            const nodeToPromote = prevNodes.find((node) => node.id === nodeId)
            if (!nodeToPromote || nodeToPromote.parentId === null) return prevNodes

            const parentNode = prevNodes.find((node) => node.id === nodeToPromote.parentId)
            const grandparentId = parentNode.parentId

            const descendants = getDescendants(prevNodes, nodeId)

            const updatedNodes = prevNodes.map((node) => {
                if (node.id === nodeId) {
                    return { ...node, parentId: grandparentId }
                }
                if (descendants.some((desc) => desc.id === node.id)) {
                    return node
                }
                return node
            })

            return updatedNodes
        })
    }

    const demoteNode = (nodeId) => {
        setNodes((prevNodes) => {
            const nodeToDemote = prevNodes.find((node) => node.id === nodeId)
            if (!nodeToDemote) return prevNodes

            const siblings = prevNodes.filter((node) => node.parentId === nodeToDemote.parentId && node.id !== nodeId)
            if (siblings.length === 0) return prevNodes

            const newParentId = siblings[0].id

            const descendants = getDescendants(prevNodes, nodeId)

            const updatedNodes = prevNodes.map((node) => {
                if (node.id === nodeId) {
                    return { ...node, parentId: newParentId }
                }
                if (descendants.some((desc) => desc.id === node.id)) {
                    return node
                }
                return node
            })

            return updatedNodes
        })
    }

    const moveNode = (id, direction) => {
        setNodes((prevNodes) => {
            const nodeIndex = prevNodes.findIndex((n) => n.id === id)
            const node = prevNodes[nodeIndex]
            const siblings = prevNodes.filter((n) => n.parentId === node.parentId)
            const siblingIndex = siblings.findIndex((n) => n.id === id)

            if (
                (direction === 'up' && siblingIndex === 0) ||
                (direction === 'down' && siblingIndex === siblings.length - 1)
            ) {
                return prevNodes
            }

            const descendants = getDescendants(prevNodes, id)
            const nodesToMove = [node, ...descendants]
            const otherNodes = prevNodes.filter((n) => !nodesToMove.some((m) => m.id === n.id))

            const targetSiblingIndex = direction === 'up' ? siblingIndex - 1 : siblingIndex + 1
            const targetSibling = siblings[targetSiblingIndex]

            const targetSiblingDescendants = getDescendants(prevNodes, targetSibling.id)
            const targetSiblingDescendantCount = targetSiblingDescendants.length

            const targetIndexInOtherNodes = otherNodes.findIndex((n) => n.id === targetSibling.id)
            const insertionIndex =
                direction === 'up'
                    ? targetIndexInOtherNodes
                    : targetIndexInOtherNodes + targetSiblingDescendantCount + 1

            const newNodes = [
                ...otherNodes.slice(0, insertionIndex),
                ...nodesToMove,
                ...otherNodes.slice(insertionIndex),
            ]

            return newNodes
        })
    }

    const demoteRootNode = (newRootText) => {
        setNodes((prevNodes) => {
            const oldRootId = getRootId()
            const newRootId = Math.max(...prevNodes.map((n) => n.id)) + 1

            const updatedNodes = prevNodes.map((node) => {
                if (node.id === oldRootId) {
                    return { ...node, parentId: newRootId }
                }
                return node
            })

            return [{ id: newRootId, text: newRootText, parentId: null }, ...updatedNodes]
        })
    }

    const getEdgeDecoration = (style) => {
        switch (style) {
            case 'curved':
                return 'bent'
            case 'zigzag':
                return 'zigzag'
            default:
                return ''
        }
    }

    const generateTikZ = () => {
        const generateGradient = (baseColor) => {
            return [baseColor, `${baseColor}!75!white`, `${baseColor}!60!white`, `${baseColor}!25!white`]
        }

        const gradientColors = generateGradient(gradientBaseColor)

        const getColor = (level, index, parentColor) => {
            if (colorStyle === 'level') {
                const levelKey = ['root', 'level1', 'level2', 'level3'][Math.min(level, 3)]
                return levelColors[levelKey]?.background || 'Black'
            } else if (colorStyle === 'gradient') {
                return gradientColors[Math.min(level, gradientColors.length - 1)]
            } else {
                if (level === 0) {
                    return colorPairs[0]?.background || 'Black'
                } else if (level === 1) {
                    return colorPairs[(index + 1) % colorPairs.length]?.background || 'RoyalBlue'
                } else if (level === 2) {
                    return parentColor || 'ForestGreen'
                } else {
                    return parentColor ? `${parentColor}!30!white` : 'Crimson'
                }
            }
        }

        const getFontColor = (backgroundColor, level, index) => {
            if (colorStyle === 'level') {
                const levelKey = ['root', 'level1', 'level2', 'level3'][Math.min(level, 3)]
                return levelColors[levelKey]?.font || 'white'
            } else if (colorStyle === 'gradient') {
                return level === 3 ? 'black' : 'white'
            } else {
                if (level === 0) {
                    return colorPairs[0]?.font || 'white'
                } else if (level === 1) {
                    return colorPairs[(index + 1) % colorPairs.length]?.font || 'white'
                } else {
                    return level === 3 ? 'black' : 'white'
                }
            }
        }

        const rootColor = getColor(0, 0, null)
        const rootFontColor = getFontColor(rootColor, 0)

        const getEdgeStyle = (style, startColor, endColor, level) => {
            if (mindmapStyle === 'default') {
                return ''
            }

            let edgeColor = endColor
            if (colorStyle === 'gradient') {
                if (level === 1) {
                    edgeColor = `${gradientBaseColor}!80!white`
                } else if (level === 2) {
                    edgeColor = `${gradientBaseColor}!57!white`
                }
            }

            let thickness
            if (level === 1) {
                thickness = style === 'straight' ? '3.5pt' : '3pt'
            } else {
                thickness = style === 'straight' ? '3pt' : '1.5pt'
            }

            if (['curved', 'zigzag'].includes(style)) {
                const decoration = getEdgeDecoration(style)
                return `edge from parent path={[decorate, decoration={${decoration}}, gradient line={${thickness}}{${startColor}}{${edgeColor}}] (\\tikzparentnode) -- (\\tikzchildnode)}`
            } else if (style === 'straight') {
                return `edge from parent path={[gradient line={${thickness}}{${startColor}}{${edgeColor}}](\\tikzparentnode) -- (\\tikzchildnode)}`
            }
        }

        let tikzCode = `
\\begin{tikzpicture}[
  mindmap,
  concept color=${rootColor},
  minimum size=0pt,
  grow cyclic,
  every node/.style={
    concept,
    font=\\sffamily\\bfseries,
    text width=,
    align=center,
    inner sep=14pt,
  },
  rotate=${globalRotation},
  root concept/.append style={
    font=\\sffamily\\bfseries\\LARGE,
    ${
        rootStyle === 'filled'
            ? `fill=${rootColor},text=${rootFontColor}`
            : rootStyle === 'outlined'
            ? `fill=white,text=black,draw=${rootColor},line width=1.5ex`
            : `ball color=${rootColor},text=${rootFontColor}`
    }
  },
  level 1 concept/.style={font=\\sffamily\\bfseries\\Large,minimum width=3cm,minimum height=2cm},
  level 2 concept/.style={font=\\sffamily\\bfseries\\large,minimum width=2cm,minimum height=1.5cm},
  level 3 concept/.style={
    font=\\sffamily\\large,
    minimum width=2cm,
    minimum height=1.5cm,
    inner sep=7pt,
  },
]
`

        const generateNodes = (parentId, level = 0, parentIndex = 0, parentColor = null) => {
            const rootId = getRootId(nodes)
            const node = nodes.find((n) => n.id === parentId)
            if (!node) return ''
            const children = nodes.filter((n) => n.parentId === parentId)
            let nodeCode = ''
            if (parentId === rootId) {
                const rootColor = getColor(0, 0, null)
                const rootFontColor =
                    rootStyle === 'filled' || rootStyle === 'ball' ? getFontColor(rootColor, 0, 0) : 'black'
                nodeCode += `\\node [root concept, text=${rootFontColor}${
                    node.nodeStyle ? `, ${node.nodeStyle}` : ''
                }] {${node.text}}\n`
            }
            children.forEach((child, index) => {
                const newColor = getColor(level + 1, index, parentColor)
                const fontColor = getFontColor(newColor, level + 1, index)
                const edgeColor = colorStyle === 'level' && level > 0 ? 'black' : newColor

                let childOptions = new Map()
                let nodeStyle = []
                let edgeFromParentPath = ''

                childOptions.set(
                    'sibling angle',
                    level === 0 ? levelOneSiblingAngle : level === 1 ? levelTwoSiblingAngle : levelThreeSiblingAngle
                )
                childOptions.set(
                    'level distance',
                    `${level === 0 ? levelOneDistance : level === 1 ? levelTwoDistance : levelThreeDistance}cm`
                )

                if (mindmapStyle === 'default') {
                    childOptions.set('concept color', newColor)
                    childOptions.set('color', edgeColor)
                    nodeStyle.push('concept')
                } else {
                    if (level === 0) {
                        childOptions.set('concept color', newColor)
                        childOptions.set('color', edgeColor)
                        nodeStyle.push('concept')
                    } else if (level === 1) {
                        edgeFromParentPath = getEdgeStyle(edgeStyle, parentColor, newColor, level)
                        nodeStyle.push(level2Shape === 'rectangle' ? 'rectangle, rounded corners' : 'ellipse')
                    } else {
                        edgeFromParentPath = getEdgeStyle(edgeStyleLevel3, parentColor, newColor, level)
                        nodeStyle.push(level3Shape === 'rectangle' ? 'rectangle, rounded corners' : 'ellipse')
                    }
                }

                if (child.edgeStyle) {
                    child.edgeStyle.split(',').forEach((style) => {
                        const [key, value] = style.split('=').map((s) => s.trim())
                        childOptions.set(key, value)
                    })
                }
                if (child.customDistance) {
                    childOptions.set('level distance', `${child.customDistance}cm`)
                }
                if (child.customAngle) {
                    childOptions.set('sibling angle', child.customAngle)
                }

                nodeStyle.push(`text=${fontColor}`)
                if (level > 0) {
                    nodeStyle.push(`draw=${newColor}`, `fill=${newColor}`)
                }

                if (child.nodeStyle) {
                    nodeStyle.push(...child.nodeStyle.split(',').map((s) => s.trim()))
                }

                let nodeContent = ''
                if (child.text.trim() !== '') {
                    nodeContent = child.text
                    if (child.image) {
                        const imageName = `image_${child.id}${getImageExtension(child.image)}`
                        nodeContent += `\\\\[.5cm] \\includegraphics[width=${child.imageScale || 100}pt]{${imageName}}`
                    }
                } else if (child.image) {
                    const imageName = `image_${child.id}${getImageExtension(child.image)}`
                    nodeContent = `\\includegraphics[width=${child.imageScale || 100}pt]{${imageName}}`
                    nodeStyle.push('align=center')
                }

                const childStyleString = Array.from(childOptions, ([key, value]) => `${key}=${value}`).join(', ')
                const fullChildStyle = edgeFromParentPath
                    ? `${childStyleString}, ${edgeFromParentPath}`
                    : childStyleString

                const indent = '  '.repeat(level + 1)
                nodeCode += `${indent}child [${fullChildStyle}] { node [level ${level + 1} concept, ${nodeStyle.join(
                    ', '
                )}] {${nodeContent}}\n`

                nodeCode += generateNodes(child.id, level + 1, index, newColor)
                nodeCode += `${indent}}\n`
            })
            return nodeCode
        }

        const rootId = getRootId(nodes)
        tikzCode += generateNodes(rootId)
        tikzCode += ';\n\\end{tikzpicture}\n'

        const fullLatexCode = `\\PassOptionsToPackage{svgnames}{xcolor}
\\documentclass[12pt,border=20pt]{standalone}
\\usepackage[utf8]{inputenc}
\\usepackage{mlmodern}
\\usepackage[T1]{fontenc}
\\usepackage{amsmath}
\\usepackage{tikz}
\\usepackage{graphicx}
\\usetikzlibrary{mindmap,shapes,decorations.pathmorphing,decorations.markings}
\\tikzset{
  gradient line/.style n args={3}{
    postaction={
    decorate,
    decoration={
      markings,
      mark=between positions 0 and \\pgfdecoratedpathlength step 1pt with {
        \\pgfmathparse{100*(\\pgfkeysvalueof{/pgf/decoration/mark info/distance from start}/\\pgfdecoratedpathlength)}
        \\pgfsetcolor{#3!\\pgfmathresult!#2}
        \\pgfpathcircle{\\pgfpointorigin}{#1}
        \\pgfusepath{fill}
        }
      }
    }
  }
}
\\usepackage{listings}
\\lstset{%
    inputencoding=utf8,
    extendedchars=true,
    literate=%
        {é}{{\\'{e}}}1    {è}{{\\\`{e}}}1    {ê}{{\\^{e}}}1    {ë}{{\\"{e}}}1
        {à}{{\\\`{a}}}1    {â}{{\\^{a}}}1    {î}{{\\^{i}}}1    {ï}{{\\"{i}}}1
        {ô}{{\\^{o}}}1    {ù}{{\\\`{u}}}1    {û}{{\\^{u}}}1    {ç}{{\\c{c}}}1,
    basicstyle=\\ttfamily,
    keywordstyle=\\bfseries,
    commentstyle=\\color{gray},
    stringstyle=\\color{darkgray},
    breaklines=true,
    showstringspaces=false,
    breakatwhitespace=true,
    tabsize=4
}

${customPreamble}

\\begin{document}
${tikzCode}
\\end{document}
`

        console.log('Generated LaTeX code:', fullLatexCode)
        setLatexCode(fullLatexCode)

        return tikzCode
    }

    const getImageExtension = (dataURL) => {
        const match = dataURL.match(/data:image\/(\w+);/)
        return match ? `.${match[1]}` : '.png'
    }

    const dataURLtoBlob = (dataURL) => {
        const arr = dataURL.split(',')
        const mime = arr[0].match(/:(.*?);/)[1]
        const bstr = atob(arr[1])
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
        }
        return new Blob([u8arr], { type: mime })
    }

    const compileTikZ = async () => {
        const tikzCode = generateTikZ()
        console.log('Compiling LaTeX code with LaTeX-On-HTTP via proxy...')
        const fullLatexCode = `\\PassOptionsToPackage{svgnames}{xcolor}
\\documentclass[12pt,border=20pt]{standalone}
\\usepackage[utf8]{inputenc}
\\usepackage{mlmodern}
\\usepackage[T1]{fontenc}
\\usepackage{amsmath}
\\usepackage{tikz}
\\usepackage{graphicx}
\\usetikzlibrary{mindmap,shapes,decorations.pathmorphing,decorations.markings}
\\tikzset{
  gradient line/.style n args={3}{
    postaction={
    decorate,
    decoration={
      markings,
      mark=between positions 0 and \\pgfdecoratedpathlength step 1pt with {
        \\pgfmathparse{100*(\\pgfkeysvalueof{/pgf/decoration/mark info/distance from start}/\\pgfdecoratedpathlength)}
        \\pgfsetcolor{#3!\\pgfmathresult!#2}
        \\pgfpathcircle{\\pgfpointorigin}{#1}
        \\pgfusepath{fill}
        }
      }
    }
  }
}
\\usepackage{listings}
\\lstset{%
    inputencoding=utf8,
    extendedchars=true,
    literate=%
        {é}{{\\'{e}}}1    {è}{{\\\`{e}}}1    {ê}{{\\^{e}}}1    {ë}{{\\"{e}}}1
        {à}{{\\\`{a}}}1    {â}{{\\^{a}}}1    {î}{{\\^{i}}}1    {ï}{{\\"{i}}}1
        {ô}{{\\^{o}}}1    {ù}{{\\\`{u}}}1    {û}{{\\^{u}}}1    {ç}{{\\c{c}}}1,
    basicstyle=\\ttfamily,
    keywordstyle=\\bfseries,
    commentstyle=\\color{gray},
    stringstyle=\\color{darkgray},
    breaklines=true,
    showstringspaces=false,
    breakatwhitespace=true,
    tabsize=4
}
${customPreamble}
\\begin{document}
${tikzCode}
\\end{document}`
        setLatexCode(fullLatexCode)
        setIsCompiling(true)

        try {
            console.log('Sending compilation request...')
            const formData = new FormData()
            formData.append('latex', fullLatexCode)
            formData.append('compiler', 'pdflatex')

            nodes.forEach((node) => {
                if (node.image) {
                    const imageBlob = dataURLtoBlob(node.image)
                    const imageName = `image_${node.id}${getImageExtension(node.image)}`
                    formData.append(imageName, imageBlob, imageName)
                }
            })

            // const response = await fetch('http://localhost:5000/compile', {
            // method: 'POST',
            // body: formData,
            // })

            const response = await fetch('/compile', {
                method: 'POST',
                body: formData,
            })

            console.log('Received response:', response.status, response.statusText)
            if (!response.ok) {
                const errorData = await response.json()
                throw new Error(`Compilation failed: ${JSON.stringify(errorData)}`)
            }
            const contentType = response.headers.get('Content-Type')
            console.log('Response content type:', contentType)
            if (contentType === 'application/pdf') {
                const pdfBlob = await response.blob()
                const pdfUrl = URL.createObjectURL(pdfBlob)
                displayPDF(pdfUrl)
            } else {
                const result = await response.text()
                console.error('Unexpected response content:', result)
                alert(
                    `Unexpected response from server. Content type: ${contentType}, Content: ${result.substring(
                        0,
                        200
                    )}...`
                )
            }
        } catch (error) {
            console.error('Error during compilation:', error)
            alert(`Error during compilation: ${error.message}`)
        } finally {
            setIsCompiling(false)
        }
    }

    const displayPDF = (pdfUrl) => {
        const modal = document.createElement('div')
        modal.style.position = 'fixed'
        modal.style.top = '0'
        modal.style.left = '0'
        modal.style.width = '100%'
        modal.style.height = '100%'
        modal.style.backgroundColor = 'rgba(0,0,0,0.7)'
        modal.style.zIndex = '1000'

        const iframe = document.createElement('iframe')
        iframe.src = pdfUrl
        iframe.style.width = '90%'
        iframe.style.height = '90%'
        iframe.style.position = 'absolute'
        iframe.style.top = '5%'
        iframe.style.left = '5%'
        iframe.style.border = 'none'

        const closeButton = document.createElement('button')
        closeButton.textContent = 'Close'
        closeButton.style.position = 'absolute'
        closeButton.style.top = '0'
        closeButton.style.right = '0'
        closeButton.onclick = () => document.body.removeChild(modal)

        modal.appendChild(iframe)
        modal.appendChild(closeButton)
        document.body.appendChild(modal)
    }

    const handleOpenLatexDialog = () => {
        generateTikZ()
        setOpenLatexDialog(true)
    }

    const handleCloseLatexDialog = () => {
        setOpenLatexDialog(false)
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(latexCode).then(
            () => {
                setOpenNotification(true)
                setNotificationMessage(t.copiedToClipboard)
            },
            (err) => {
                console.error('Could not copy text: ', err)
            }
        )
    }

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenNotification(false)
    }

    const ColorPairInput = ({ index, pair, onChange }) => {
        const [localPair, setLocalPair] = React.useState(pair)

        const handleChange = (field, value) => {
            setLocalPair((prev) => ({ ...prev, [field]: value }))
        }

        const handleBlur = () => {
            onChange(index, localPair)
        }

        React.useEffect(() => {
            setLocalPair(pair)
        }, [pair])

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TextField
                    label={`${t.backgroundColor} ${index + 1}`}
                    value={localPair.background}
                    onChange={(e) => handleChange('background', e.target.value)}
                    onBlur={handleBlur}
                    sx={{ mr: 2, flex: 1 }}
                />
                <TextField
                    label={`${t.fontColor} ${index + 1}`}
                    value={localPair.font}
                    onChange={(e) => handleChange('font', e.target.value)}
                    onBlur={handleBlur}
                    sx={{ flex: 1 }}
                />
            </Box>
        )
    }

    const LevelColorPairInput = ({ level, pair, onChange }) => {
        const [localPair, setLocalPair] = React.useState(pair)

        const handleChange = (field, value) => {
            setLocalPair((prev) => ({ ...prev, [field]: value }))
        }

        const handleBlur = () => {
            onChange(level, localPair)
        }

        React.useEffect(() => {
            setLocalPair(pair)
        }, [pair])

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <TextField
                    label={t.backgroundColor}
                    value={localPair.background}
                    onChange={(e) => handleChange('background', e.target.value)}
                    onBlur={handleBlur}
                    sx={{ mr: 2, flex: 1 }}
                />
                <TextField
                    label={t.fontColor}
                    value={localPair.font}
                    onChange={(e) => handleChange('font', e.target.value)}
                    onBlur={handleBlur}
                    sx={{ flex: 1 }}
                />
            </Box>
        )
    }

    useEffect(() => {
        setNodes((prevNodes) => {
            const rootNode = prevNodes.find((node) => node.id === 1)
            if (rootNode && rootNode.text === t.root) {
                return [{ ...rootNode, text: language === 'en' ? 'Root' : 'Racine' }, ...prevNodes.slice(1)]
            }
            return prevNodes
        })
    }, [language, t.root])

    const getNodePadding = (nodeId) => {
        const level = getNodeLevel(nodeId)
        const basePadding = 4
        const levelPadding = 4
        return basePadding + level * levelPadding
    }

    const getMenuItemPadding = (nodeId) => {
        const level = getNodeLevel(nodeId)
        const basePadding = 8
        const indentationPerLevel = 16
        return `${basePadding + level * indentationPerLevel}px`
    }

    const downloadLatexCode = () => {
        const rootNodeText = nodes.find((node) => node.id === 1)?.text || 'mindmap'
        const fileName = rootNodeText.toLowerCase().replace(/\s+/g, '_') + '.tex'
        const blob = new Blob([latexCode], { type: 'text/plain' })
        const href = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = href
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(href)
    }

    const toggleLanguage = () => {
        const newLang = language === 'en' ? 'fr' : 'en'
        setLanguage(newLang)
        const url = new URL(window.location)
        url.searchParams.set('lang', newLang)
        window.history.pushState({}, '', url)
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <ResponsiveAppBar
                isDarkMode={isDarkMode}
                toggleDarkMode={toggleDarkMode}
                setIsDocumentationOpen={setIsDocumentationOpen}
                language={language}
                toggleLanguage={toggleLanguage}
                uploadJSON={uploadJSON}
                downloadJSON={downloadJSON}
                downloadLatexCode={downloadLatexCode}
                t={t}
            />
            <Box
                sx={{
                    minHeight: 'calc(100vh - 64px)',
                    bgcolor: 'background.default',
                    py: 3,
                }}
            >
                <Container maxWidth="lg">
                    <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                        <Tabs
                            value={activeTab}
                            onChange={(e, newValue) => setActiveTab(newValue)}
                            sx={{ mb: 2 }}
                            variant={isMobile ? 'scrollable' : 'standard'}
                            scrollButtons="auto"
                        >
                            <Tab label={t.addNode} />
                            <Tab label={t.editNodes} />
                            <Tab label={t.layoutOptions} />
                            <Tab label={t.colorOptions} />
                            <Tab label={t.edgeAndNodeStyle} />
                            <Tab label={t.customPreamble} />
                        </Tabs>

                        {activeTab === 0 && (
                            <>
                                <Box sx={{ mb: 4, mt: 4 }}>
                                    <StyledTextField
                                        fullWidth
                                        label={t.rootNodeTitle}
                                        value={nodes[0].text}
                                        onChange={(e) => {
                                            const newRootText = e.target.value
                                            setNodes((prevNodes) => [
                                                { ...prevNodes[0], text: newRootText },
                                                ...prevNodes.slice(1),
                                            ])
                                        }}
                                        placeholder={t.enterRootNodeText}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Box>
                                <Box sx={{ mb: 3 }}>
                                    <StyledTextField
                                        fullWidth
                                        multiline
                                        minRows={1}
                                        maxRows={3}
                                        label={t.addChildNode}
                                        value={currentNode}
                                        onChange={(e) => setCurrentNode(e.target.value)}
                                        placeholder={t.enterNodeText}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            endAdornment: (
                                                <IconButton
                                                    onClick={() =>
                                                        document.getElementById('newNodeImageUpload').click()
                                                    }
                                                    edge="end"
                                                    sx={{ position: 'absolute', bottom: 8, right: 12 }}
                                                >
                                                    <ImageIcon />
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                    <input
                                        id="newNodeImageUpload"
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => {
                                            const file = e.target.files[0]
                                            if (file) {
                                                const reader = new FileReader()
                                                reader.onload = (event) => {
                                                    setNewNodeImage(event.target.result)
                                                    setNewNodeImageScale(100)
                                                }
                                                reader.readAsDataURL(file)
                                            }
                                        }}
                                        style={{ display: 'none' }}
                                    />
                                </Box>
                                {newNodeImage && (
                                    <Box mt={1} mb={2}>
                                        <div
                                            style={{
                                                width: '200px',
                                                height: '200px',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <img
                                                src={newNodeImage}
                                                alt=""
                                                style={{
                                                    maxWidth: '100%',
                                                    maxHeight: '100%',
                                                    width: 'auto',
                                                    height: 'auto',
                                                    transform: `scale(${(newNodeImageScale || 100) / 100})`,
                                                    transformOrigin: 'center center',
                                                }}
                                            />
                                        </div>
                                        <Slider
                                            value={newNodeImageScale || 100}
                                            onChange={(_, newValue) => setNewNodeImageScale(newValue)}
                                            aria-labelledby="new-image-scale-slider"
                                            valueLabelDisplay="auto"
                                            min={10}
                                            max={100}
                                            sx={{ mt: 2 }}
                                        />
                                        <Button
                                            startIcon={<DeleteIcon />}
                                            onClick={() => {
                                                setNewNodeImage(null)
                                                setNewNodeImageScale(100)
                                            }}
                                            sx={{ mt: 1 }}
                                        >
                                            {t.deleteImage}
                                        </Button>
                                    </Box>
                                )}
                                <StyledFormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel id="parent-node-label">{t.parentNode}</InputLabel>
                                    <Select
                                        labelId="parent-node-label"
                                        value={currentParentId}
                                        onChange={(e) => setCurrentParentId(e.target.value)}
                                        label={t.parentNode}
                                        /* sx={{ backgroundColor: '#eceef6', border: 'none' }} */
                                    >
                                        {renderNodeOptions()}
                                    </Select>
                                </StyledFormControl>
                                <Button
                                    onClick={addNode}
                                    variant="contained"
                                    startIcon={<AddIcon />}
                                    sx={{
                                        width: 'auto',
                                        margin: '0 auto',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mt: 2,
                                        backgroundColor: theme.palette.mode === 'light' ? '#5465b7' : '#90caf9',
                                    }}
                                >
                                    {t.addNode}
                                </Button>
                            </>
                        )}

                        {activeTab === 1 && (
                            <>
                                <List>
                                    {nodes.map((node) => (
                                        <ListItem
                                            key={node.id}
                                            sx={{
                                                flexDirection: 'column',
                                                alignItems: 'stretch',
                                                mb: 2,
                                                pl: getNodePadding(node.id),
                                            }}
                                        >
                                            <Paper elevation={1} sx={{ p: 2 }}>
                                                <DynamicTextField
                                                    fullWidth
                                                    multiline
                                                    minRows={1}
                                                    maxRows={2}
                                                    value={node.text}
                                                    onChange={(e) => updateNodeText(node.id, e.target.value)}
                                                    label={t.nodeText}
                                                    variant="outlined"
                                                    sx={{ mb: 2 }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <IconButton
                                                                onClick={() =>
                                                                    document
                                                                        .getElementById(`nodeImageUpload-${node.id}`)
                                                                        .click()
                                                                }
                                                                edge="end"
                                                                sx={{ position: 'absolute', bottom: 8, right: 8 }}
                                                            >
                                                                <ImageIcon />
                                                            </IconButton>
                                                        ),
                                                    }}
                                                />
                                                <input
                                                    id={`nodeImageUpload-${node.id}`}
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={(e) => {
                                                        const file = e.target.files[0]
                                                        if (file) {
                                                            const reader = new FileReader()
                                                            reader.onload = (event) => {
                                                                updateNodeProperty(
                                                                    node.id,
                                                                    'image',
                                                                    event.target.result
                                                                )
                                                                updateNodeProperty(node.id, 'imageScale', 100)
                                                            }
                                                            reader.readAsDataURL(file)
                                                        }
                                                    }}
                                                    style={{ display: 'none' }}
                                                />

                                                {node.image && (
                                                    <Box mt={1} mb={2}>
                                                        <div
                                                            style={{
                                                                width: '200px',
                                                                height: '200px',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            <img
                                                                src={node.image}
                                                                alt=""
                                                                style={{
                                                                    maxWidth: '100%',
                                                                    maxHeight: '100%',
                                                                    width: 'auto',
                                                                    height: 'auto',
                                                                    transform: `scale(${
                                                                        (node.imageScale || 100) / 100
                                                                    })`,
                                                                    transformOrigin: 'center center',
                                                                }}
                                                            />
                                                        </div>
                                                        <Slider
                                                            value={node.imageScale || 100}
                                                            onChange={(_, newValue) =>
                                                                updateNodeProperty(node.id, 'imageScale', newValue)
                                                            }
                                                            aria-labelledby="image-scale-slider"
                                                            valueLabelDisplay="auto"
                                                            min={10}
                                                            max={100}
                                                            sx={{ mt: 2 }}
                                                        />
                                                        <Button
                                                            startIcon={<DeleteIcon />}
                                                            onClick={() => {
                                                                updateNodeProperty(node.id, 'image', null)
                                                                updateNodeProperty(node.id, 'imageScale', 100)
                                                            }}
                                                            sx={{ mt: 1 }}
                                                        >
                                                            {t.deleteImage}
                                                        </Button>
                                                    </Box>
                                                )}
                                                <Grid container spacing={2} sx={{ mb: 2 }}>
                                                    <Grid item xs={6}>
                                                        <DynamicTextField
                                                            fullWidth
                                                            multiline
                                                            minRows={1}
                                                            maxRows={2}
                                                            value={node.edgeStyle}
                                                            onChange={(e) =>
                                                                updateNodeProperty(node.id, 'edgeStyle', e.target.value)
                                                            }
                                                            label={t.edgeStyle}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <DynamicTextField
                                                            fullWidth
                                                            multiline
                                                            minRows={1}
                                                            maxRows={2}
                                                            value={node.nodeStyle}
                                                            onChange={(e) =>
                                                                updateNodeProperty(node.id, 'nodeStyle', e.target.value)
                                                            }
                                                            label={t.nodeStyle}
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                    <IconButton
                                                        onClick={() => promoteNode(node.id)}
                                                        disabled={node.id === 1 || node.parentId === 1}
                                                        title={t.promoteNode}
                                                    >
                                                        <ChevronLeft />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            if (node.id === 1) {
                                                                setOpenDemoteRootDialog(true)
                                                            } else {
                                                                demoteNode(node.id)
                                                            }
                                                        }}
                                                        title={node.id === 1 ? t.demoteRootNode : t.demoteNode}
                                                    >
                                                        <ChevronRight />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => moveNode(node.id, 'up')}
                                                        disabled={node.id === 1}
                                                        title={t.moveNodeUp}
                                                    >
                                                        <KeyboardArrowUpIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => moveNode(node.id, 'down')}
                                                        disabled={node.id === 1}
                                                        title={t.moveNodeDown}
                                                    >
                                                        <KeyboardArrowDownIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => deleteNode(node.id)}
                                                        disabled={node.id === 1}
                                                        color="error"
                                                        title={t.deleteNode}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            </Paper>
                                        </ListItem>
                                    ))}
                                </List>

                                <Dialog
                                    open={openDemoteRootDialog}
                                    onClose={() => {
                                        setOpenDemoteRootDialog(false)
                                        setNewRootText('')
                                    }}
                                >
                                    <DialogTitle>{t.enterNewRootNode}</DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            label={t.newRootNodeText}
                                            type="text"
                                            fullWidth
                                            value={newRootText}
                                            onChange={(e) => setNewRootText(e.target.value)}
                                            placeholder={t.newRootNodePlaceholder}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            onClick={() => {
                                                setOpenDemoteRootDialog(false)
                                                setNewRootText('')
                                            }}
                                        >
                                            {t.cancel}
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                if (newRootText.trim() !== '') {
                                                    demoteRootNode(newRootText.trim())
                                                    setOpenDemoteRootDialog(false)
                                                    setNewRootText('')
                                                }
                                            }}
                                            color="primary"
                                            disabled={newRootText.trim() === ''}
                                        >
                                            {t.confirm}
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </>
                        )}

                        {activeTab === 2 && (
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography gutterBottom>
                                        {t.globalRotation} ({globalRotation}°)
                                    </Typography>
                                    <Slider
                                        value={globalRotation}
                                        onChange={(e, newValue) => setGlobalRotation(newValue)}
                                        min={0}
                                        max={360}
                                        step={1}
                                        sx={(theme) => ({
                                            color: theme.palette.mode === 'light' ? '#5465b7' : '#90caf9',
                                            '& .MuiSlider-thumb': {
                                                color: theme.palette.mode === 'light' ? '#5465b7' : '#90caf9',
                                            },
                                            '& .MuiSlider-rail': {
                                                color: theme.palette.mode === 'light' ? '#bac2e4' : '#6b6b6b',
                                                opacity: theme.palette.mode === 'light' ? 1 : 0.5,
                                            },
                                            '& .MuiSlider-track': {
                                                color: theme.palette.mode === 'light' ? '#5465b7' : '#90caf9',
                                            },
                                            '& .MuiSlider-valueLabel': {
                                                backgroundColor: theme.palette.mode === 'light' ? '#5465b7' : '#90caf9',
                                            },
                                        })}
                                    />
                                </Grid>
                                {[
                                    {
                                        label: t.level1Distance,
                                        value: levelOneDistance,
                                        setValue: setLevelOneDistance,
                                        min: 2,
                                        max: 15,
                                    },
                                    {
                                        label: t.level1SiblingAngle,
                                        value: levelOneSiblingAngle,
                                        setValue: setLevelOneSiblingAngle,
                                        min: 30,
                                        max: 360,
                                    },
                                    {
                                        label: t.level2Distance,
                                        value: levelTwoDistance,
                                        setValue: setLevelTwoDistance,
                                        min: 2,
                                        max: 12,
                                    },
                                    {
                                        label: t.level2SiblingAngle,
                                        value: levelTwoSiblingAngle,
                                        setValue: setLevelTwoSiblingAngle,
                                        min: 30,
                                        max: 360,
                                    },
                                    {
                                        label: t.level3Distance,
                                        value: levelThreeDistance,
                                        setValue: setLevelThreeDistance,
                                        min: 2,
                                        max: 12,
                                    },
                                    {
                                        label: t.level3SiblingAngle,
                                        value: levelThreeSiblingAngle,
                                        setValue: setLevelThreeSiblingAngle,
                                        min: 15,
                                        max: 180,
                                    },
                                ].map((item, index) => (
                                    <Grid item xs={12} sm={6} key={index}>
                                        <Typography gutterBottom>
                                            {item.label} ({item.value}
                                            {item.label.includes('Distance') ? ' cm' : '°'})
                                        </Typography>
                                        <Slider
                                            value={item.value}
                                            onChange={(e, newValue) => item.setValue(newValue)}
                                            min={item.min}
                                            max={item.max}
                                            step={item.label.includes('Distance') ? 0.1 : 1}
                                            sx={(theme) => ({
                                                color: theme.palette.mode === 'light' ? '#5465b7' : '#90caf9',
                                                '& .MuiSlider-thumb': {
                                                    color: theme.palette.mode === 'light' ? '#5465b7' : '#90caf9',
                                                },
                                                '& .MuiSlider-rail': {
                                                    color: theme.palette.mode === 'light' ? '#bac2e4' : '#6b6b6b',
                                                    opacity: theme.palette.mode === 'light' ? 1 : 0.5,
                                                },
                                                '& .MuiSlider-track': {
                                                    color: theme.palette.mode === 'light' ? '#5465b7' : '#90caf9',
                                                },
                                                '& .MuiSlider-valueLabel': {
                                                    backgroundColor:
                                                        theme.palette.mode === 'light' ? '#5465b7' : '#90caf9',
                                                },
                                            })}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        )}

                        {activeTab === 3 && (
                            <>
                                <StyledFormControl fullWidth sx={{ mb: 3 }}>
                                    <InputLabel id="color-style-label">{t.colorStyle}</InputLabel>
                                    <Select
                                        labelId="color-style-label"
                                        value={colorStyle}
                                        onChange={(e) => setColorStyle(e.target.value)}
                                        label={t.colorStyle}
                                    >
                                        <MenuItem value="subtree">{t.colorBySubtree}</MenuItem>
                                        <MenuItem value="gradient">{t.singleColorGradient}</MenuItem>
                                        <MenuItem value="level">{t.sameColorPerLevel}</MenuItem>
                                    </Select>
                                </StyledFormControl>
                                {colorStyle === 'subtree' && (
                                    <>
                                        <Button
                                            onClick={() => setShowColorPairs(!showColorPairs)}
                                            variant="outlined"
                                            fullWidth
                                            sx={{ mb: 2 }}
                                        >
                                            {showColorPairs ? t.hideColorPairs : t.showColorPairs}
                                        </Button>
                                        {showColorPairs && (
                                            <>
                                                {colorPairs.map((pair, index) => (
                                                    <ColorPairInput
                                                        key={index}
                                                        index={index}
                                                        pair={pair}
                                                        onChange={(index, newPair) => {
                                                            setColorPairs((prevPairs) => {
                                                                const newPairs = [...prevPairs]
                                                                newPairs[index] = newPair
                                                                return newPairs
                                                            })
                                                        }}
                                                    />
                                                ))}
                                                <Button
                                                    onClick={() =>
                                                        setColorPairs((prevPairs) => [
                                                            ...prevPairs,
                                                            { background: 'Gray', font: 'white' },
                                                        ])
                                                    }
                                                    variant="outlined"
                                                    fullWidth
                                                    sx={{ mt: 2 }}
                                                >
                                                    {t.addColorPair}
                                                </Button>
                                            </>
                                        )}
                                    </>
                                )}
                                {colorStyle === 'gradient' && (
                                    <TextField
                                        label={t.gradientBaseColor}
                                        value={gradientBaseColor}
                                        onChange={(e) => setGradientBaseColor(e.target.value)}
                                        helperText={t.validColorName}
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                                {colorStyle === 'level' && (
                                    <>
                                        <Button
                                            onClick={() => setShowColorPairs(!showColorPairs)}
                                            variant="outlined"
                                            fullWidth
                                            sx={{ mb: 2 }}
                                        >
                                            {showColorPairs ? t.hideLevelColors : t.showLevelColors}
                                        </Button>
                                        {showColorPairs && (
                                            <>
                                                {Object.entries(levelColors).map(([level, colorPair]) => (
                                                    <LevelColorPairInput
                                                        key={level}
                                                        level={level}
                                                        pair={colorPair}
                                                        onChange={(level, newPair) => {
                                                            setLevelColors((prev) => ({
                                                                ...prev,
                                                                [level]: newPair,
                                                            }))
                                                        }}
                                                    />
                                                ))}
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}

                        {activeTab === 4 && (
                            <>
                                <StyledFormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel id="root-style-label">{t.rootNodeStyle}</InputLabel>
                                    <Select
                                        labelId="root-style-label"
                                        value={rootStyle}
                                        onChange={(e) => setRootStyle(e.target.value)}
                                        label={t.rootNodeStyle}
                                    >
                                        <MenuItem value="filled">{t.filled}</MenuItem>
                                        <MenuItem value="outlined">{t.outlined}</MenuItem>
                                        <MenuItem value="ball">{t.ball}</MenuItem>
                                    </Select>
                                </StyledFormControl>
                                <StyledFormControl fullWidth sx={{ mb: 2 }}>
                                    <InputLabel id="mindmap-style-label">{t.mindmapStyle}</InputLabel>
                                    <Select
                                        labelId="mindmap-style-label"
                                        value={mindmapStyle}
                                        onChange={(e) => {
                                            setMindmapStyle(e.target.value)
                                            if (e.target.value === 'default') {
                                                setEdgeStyle('default')
                                                setEdgeStyleLevel3('default')
                                                setLevel2Shape('ellipse')
                                                setLevel3Shape('rectangle')
                                            }
                                        }}
                                        label={t.mindmapStyle}
                                    >
                                        <MenuItem value="default">{t.defaultMindmapStyle}</MenuItem>
                                        <MenuItem value="custom">{t.customMindmapStyle}</MenuItem>
                                    </Select>
                                </StyledFormControl>

                                {mindmapStyle === 'custom' && (
                                    <>
                                        <StyledFormControl fullWidth sx={{ mb: 2 }}>
                                            <InputLabel id="edge-style-label">{t.edgeStyleLevel1to2}</InputLabel>
                                            <Select
                                                labelId="edge-style-label"
                                                value={edgeStyle}
                                                onChange={(e) => setEdgeStyle(e.target.value)}
                                                label={t.edgeStyleLevel1to2}
                                            >
                                                <MenuItem value="straight">{t.straightEdges}</MenuItem>
                                                <MenuItem value="curved">{t.curvedEdges}</MenuItem>
                                                <MenuItem value="zigzag">{t.zigzagDecoration}</MenuItem>
                                            </Select>
                                        </StyledFormControl>
                                        <StyledFormControl fullWidth sx={{ mb: 2 }}>
                                            <InputLabel id="edge-style-level3-label">{t.edgeStyleLevel2to3}</InputLabel>
                                            <Select
                                                labelId="edge-style-level3-label"
                                                value={edgeStyleLevel3}
                                                onChange={(e) => setEdgeStyleLevel3(e.target.value)}
                                                label={t.edgeStyleLevel2to3}
                                            >
                                                <MenuItem value="straight">{t.straightEdges}</MenuItem>
                                                <MenuItem value="curved">{t.curvedEdges}</MenuItem>
                                                <MenuItem value="zigzag">{t.zigzagDecoration}</MenuItem>
                                            </Select>
                                        </StyledFormControl>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <StyledFormControl fullWidth>
                                                    <InputLabel id="level-2-shape-label">
                                                        {t.level2NodeShape}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="level-2-shape-label"
                                                        value={level2Shape}
                                                        onChange={(e) => setLevel2Shape(e.target.value)}
                                                        label={t.level2NodeShape}
                                                    >
                                                        <MenuItem value="ellipse">Ellipse</MenuItem>
                                                        <MenuItem value="rectangle">Rectangle</MenuItem>
                                                    </Select>
                                                </StyledFormControl>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <StyledFormControl fullWidth>
                                                    <InputLabel id="level-3-shape-label">
                                                        {t.level3NodeShape}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="level-3-shape-label"
                                                        value={level3Shape}
                                                        onChange={(e) => setLevel3Shape(e.target.value)}
                                                        label={t.level3NodeShape}
                                                    >
                                                        <MenuItem value="ellipse">Ellipse</MenuItem>
                                                        <MenuItem value="rectangle">Rectangle</MenuItem>
                                                    </Select>
                                                </StyledFormControl>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </>
                        )}

                        {activeTab === 5 && (
                            <>
                                <DynamicTextField
                                    fullWidth
                                    multiline
                                    minRows={1}
                                    maxRows={4}
                                    value={customPreamble}
                                    onChange={(e) => setCustomPreamble(e.target.value)}
                                    placeholder={`\\usepackage{booktabs}`}
                                    variant="outlined"
                                />
                            </>
                        )}
                    </Paper>

                    <MindMapControlPanel
                        onUpload={uploadJSON}
                        onDownload={downloadJSON}
                        onGenerateAndView={compileTikZ}
                        onViewLatexCode={handleOpenLatexDialog}
                        onDownloadTexFile={downloadLatexCode}
                        isCompiling={isCompiling}
                        t={t}
                    />
                </Container>
            </Box>

            <LatexCodeDialog
                open={openLatexDialog}
                onClose={handleCloseLatexDialog}
                latexCode={latexCode}
                onCopy={copyToClipboard}
                t={t}
            />

            <Modal
                open={isDocumentationOpen}
                onClose={() => setIsDocumentationOpen(false)}
                aria-labelledby="documentation-modal"
                aria-describedby="documentation-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        maxWidth: 800,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        maxHeight: '90vh',
                        overflow: 'auto',
                    }}
                >
                    <DocumentationContent onClose={() => setIsDocumentationOpen(false)} language={language} />
                </Box>
            </Modal>

            <Snackbar open={openNotification} autoHideDuration={2000} onClose={handleCloseNotification}>
                <MuiAlert onClose={handleCloseNotification} severity="success" sx={{ width: '100%' }}>
                    {notificationMessage}
                </MuiAlert>
            </Snackbar>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isCompiling}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </ThemeProvider>
    )
}

export default App
