const MindMapJSONIO = ({
    nodes,
    setNodes,
    customPreamble,
    setCustomPreamble,
    globalRotation,
    setGlobalRotation,
    levelOneDistance,
    setLevelOneDistance,
    levelTwoDistance,
    setLevelTwoDistance,
    levelThreeDistance,
    setLevelThreeDistance,
    levelOneSiblingAngle,
    setLevelOneSiblingAngle,
    levelTwoSiblingAngle,
    setLevelTwoSiblingAngle,
    levelThreeSiblingAngle,
    setLevelThreeSiblingAngle,
    mindmapStyle,
    setMindmapStyle,
    edgeStyle,
    setEdgeStyle,
    edgeStyleLevel3,
    setEdgeStyleLevel3,
    rootStyle,
    setRootStyle,
    level2Shape,
    setLevel2Shape,
    level3Shape,
    setLevel3Shape,
    colorStyle,
    setColorStyle,
}) => {
    const downloadJSON = () => {
        const data = JSON.stringify(
            {
                nodes,
                customPreamble,
                globalRotation,
                levelOneDistance,
                levelTwoDistance,
                levelThreeDistance,
                levelOneSiblingAngle,
                levelTwoSiblingAngle,
                levelThreeSiblingAngle,
                mindmapStyle,
                edgeStyle,
                edgeStyleLevel3,
                rootStyle,
                level2Shape,
                level3Shape,
                colorStyle,
            },
            null,
            2
        )
        const blob = new Blob([data], { type: 'application/json' })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        const rootNodeText = nodes.find((node) => node.parentId === null)?.text || 'mindmap'
        link.download = `${rootNodeText.toLowerCase().replace(/\s+/g, '_')}.json`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    }

    const uploadJSON = () => {
        const input = document.createElement('input')
        input.type = 'file'
        input.accept = '.json'
        input.onchange = (event) => {
            const file = event.target.files[0]
            if (file) {
                const reader = new FileReader()
                reader.onload = (e) => {
                    try {
                        const uploadedData = JSON.parse(e.target.result)
                        setNodes(uploadedData.nodes || nodes)
                        setCustomPreamble(uploadedData.customPreamble || customPreamble)
                        setGlobalRotation(uploadedData.globalRotation || globalRotation)
                        setLevelOneDistance(uploadedData.levelOneDistance || levelOneDistance)
                        setLevelTwoDistance(uploadedData.levelTwoDistance || levelTwoDistance)
                        setLevelThreeDistance(uploadedData.levelThreeDistance || levelThreeDistance)
                        setLevelOneSiblingAngle(uploadedData.levelOneSiblingAngle || levelOneSiblingAngle)
                        setLevelTwoSiblingAngle(uploadedData.levelTwoSiblingAngle || levelTwoSiblingAngle)
                        setLevelThreeSiblingAngle(uploadedData.levelThreeSiblingAngle || levelThreeSiblingAngle)
                        setMindmapStyle(uploadedData.mindmapStyle || mindmapStyle)
                        setEdgeStyle(uploadedData.edgeStyle || edgeStyle)
                        setEdgeStyleLevel3(uploadedData.edgeStyleLevel3 || edgeStyleLevel3)
                        setRootStyle(uploadedData.rootStyle || rootStyle)
                        setLevel2Shape(uploadedData.level2Shape || level2Shape)
                        setLevel3Shape(uploadedData.level3Shape || level3Shape)
                        setColorStyle(uploadedData.colorStyle || colorStyle)
                    } catch (error) {
                        console.error('Error parsing JSON:', error)
                        alert("Error parsing JSON file. Please make sure it's a valid mind map file.")
                    }
                }
                reader.readAsText(file)
            }
        }
        input.click()
    }

    return { uploadJSON, downloadJSON }
}

export default MindMapJSONIO
